import { Request } from '@/http/request'

// 话单日账单统计数据查询
export function dailyBillCount(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/daily-bill-count', parameter)
}
// 话单日账单列表查询
export function dailyBillList(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/daily-bill-list', parameter)
}
// 隐私号用户价格查询
export function monthlyBillCount(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/monthly-bill-count', parameter)
}
// 价格接口删除 
export function monthlyBillList(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/monthly-bill-list', parameter)
}
// 消费统计列表数据 
export function accountList(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/account', parameter)
}
// 消费统计列表数据--->主叫 
export function accountCallingList(parameter: any){
  return Request.axiosInstance.post('/open/num-agent-count/list', parameter)
}
// 消费统计列表数据--->主叫 导出
export function accountCallingExport(parameter: any){
  return Request.axiosInstance.post('/open/num-agent-count/export', parameter)
}
// 表单导出 
export function exportList(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/export', parameter)
}
// 聚合导出 
export function groupExport(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/group-export', parameter)
}


// 消息详情列表查询 
export function accountDetailLine(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/account-detail-line', parameter)
}
//消费详情导出
export function accountDetailExport(parameter: any){
  return Request.axiosInstance.post('/open/user-number-count/account-detail-export', parameter)
}
export default {accountDetailExport, accountDetailLine, groupExport,accountCallingExport,dailyBillCount, dailyBillList, monthlyBillCount, monthlyBillList, accountList, exportList,accountCallingList }
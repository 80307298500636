<template>
  <div class="newbill">
    <!-- 1.时间选择条件搜索框 -->
    <div class="flex-nowrap-space-between mb-20">
      <div class="flex-nowrap-space-around">
        <div class="mr-20 first-name">时间选择</div>
        <el-radio-group v-model="time" size="small" @change="setTimeData">
          <el-radio-button label="1">今日</el-radio-button>
          <el-radio-button label="2">昨天</el-radio-button>
          <el-radio-button label="3">本周</el-radio-button>
          <el-radio-button label="4">上周</el-radio-button>
          <el-radio-button label="5">本月</el-radio-button>
          <el-radio-button label="6">上月</el-radio-button>
        </el-radio-group>
        <div class="ml-20">
          <el-date-picker
            v-model="timeList"
            @change="setDataList"
            type="daterange"
            size="small"
            :clearable="false"
            value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <!-- 2.数据维度条件搜索框 -->
    <div class="flex-nowrap-flex-start mb-20">
      <div class="flex-nowrap-space-around">
        <div class="mr-20 first-name">数据维度</div>
        <el-radio-group
          v-model="searchFrom.groupBy[0]"
          size="small"
          @change="setTypeData"
        >
          <el-radio-button label="user">
            <span class="title-span">用户</span>
          </el-radio-button>
          <el-radio-button label="amount">
            <span class="title-span">线路</span>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!-- 3.汇总 -->

    <div class="" style="display: flex">
      <div style="margin-right: 5px; width: 35%">
        <el-table
        :highlight-current-row="true"
          :row-class-name="rowClassName"
          @row-click="rowClick"
          :default-sort="{ prop: 'date', order: 'descending' }"
          v-loading="loading"
          ref="multipleTable"
          size="mini"
          :data="countList"
          tooltip-effect="dark"
          style="width: 100%"
          :cell-style="{ padding: '0px',background: 'revert' }"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          row-key="id"
          :max-height="tableHeight"
          default-expand-all
          :row-style="{ height: '50px' }"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            :label="searchFrom.groupBy[0] == 'user' ? '渠道用户' : '线路名称'"
          >
            <template #default="scope">{{
              searchFrom.groupBy[0] == "user"
                ? scope.row.nickName
                : scope.row.amountName
            }}</template>
          </el-table-column>
          <el-table-column label="违规次数" sortable prop="total">
            <template #default="scope">{{ scope.row.total }}</template>
          </el-table-column>
        </el-table>
      </div>

      <div style="width: 68%">
        <el-table
          class="eltable"
          v-loading="loading"
          ref="multipleTable"
          size="mini"
          :data="downTable"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          row-key="id"
          :max-height="tableHeight"
          default-expand-all
          @sort-change="changeTableSort"
          :row-style="{ height: '50px' }"
          :cell-style="{ padding: '0px',background: 'revert' }"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
          
            :label="searchFrom.groupBy[0] == 'amount' ? '渠道用户' : '线路名称'"
          >
            <template #default="scope">{{
              searchFrom.groupBy[0] == "amount"
                ? scope.row.nickName
                : scope.row.amountName
            }}</template>
          </el-table-column>

          <el-table-column label="特大">
            <template #default="scope">
              <div style="color: #e34559">
                {{ scope.row.extraLargeTotal }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="重大">
            <template #default="scope">
              <div style="color: #f9c37e">
                {{ scope.row.significantTotal }}
              </div></template
            >
          </el-table-column>
          <el-table-column label="一般">
            <template #default="scope">
              <div style="color: #ffe180">
                {{ scope.row.generalTotal }}
              </div></template
            >
          </el-table-column>

          <el-table-column label="轻微">
            <template #default="scope">
              <div style="color: #93b160">
                {{ scope.row.slightTotal }}
              </div></template
            >
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 4.数据表格 -->

    <!-- 5.分页器 -->
    <!-- <div class="flex-nowrap-flex-end pagination-box">
      <el-pagination v-model="searchFrom.page" background :page-sizes="[10, 50, 100, 200]"
        :page-size="searchFrom.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div> -->
    <!-- 聚合导出模态框 -->
    <el-dialog
      title="聚合导出"
      v-model="exportDialogVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
    >
      <div class="">
        <el-form
          label-position="left"
          label-width="130px"
          size="small"
          :model="exportForm"
          :rules="rules"
          ref="exportForm"
        >
          <div class="form-out-box p-0-30">
            <el-form-item label="服务商：">
              <el-select
                v-model="exportForm.amountId"
                filterable
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="(item, index) in amount_id"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口类型：" prop="interfaceId">
              <el-select
                v-model="exportForm.interfaceId"
                placeholder="请选择"
                filterable
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="(item, index) in interface_id"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始日期：" prop="pickerDate">
              <el-date-picker
                @change="selectDate"
                v-model="pickerDate"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
                value-format="YYYY-MM-DD"
                :default-time="pickerDate"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="大于分钟数：" prop="callTimeBegin">
              <el-input
                v-model.trim="exportForm.callTimeBegin"
                placeholder="请输入大于分钟数"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小于分钟数：" prop="callTimeEnd">
              <el-input
                v-model.trim="exportForm.callTimeEnd"
                placeholder="请输入小于分钟数"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="exportReset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="hanldeExport" round>
                确认
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userNumberCount from "@/api/open/userNumberCount/userNumberCount";
import { getDate, getMonday, getMonth } from "@/utils/timeToChoose.js";
import amountts from "@/api/open/privacy/amount";
import interfacets from "@/api/open/privacy/interface";
import userts from "@/api/web/user";
import { IconFont } from "@/utils/iconfont";
import { ElMessage } from "element-plus";
import { message } from "ant-design-vue";
import userApp from "@/api/web/userApp";
export default {
  name: "Newbill",
  components: {
    IconFont,
  },
  data() {
    const validateMinutes = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("分钟数输入格式有误，请不要输入除数字外的其他字符"));
      } else {
        callback();
      }
    };
    return {
      selectedRow: null,
      exportDialogVisible: false, //聚合导出
      detailVisible: true, //详情按钮显示
      time: "1",
      total: 0,
      type: "amount",
      timeList: [],
      loading: false,
      countList: [],
      downTable: [],
      //聚合导出条件
      exportForm: {
        amountId: "", //服务商id
        interfaceId: "", //接口id
        dateBegin: "",
        dateEnd: "",
        callTimeBegin: "", //大于分钟数
        callTimeEnd: "", //小于分钟数
      },
      pickerDate: [getDate(0), getDate(0)], //选中时间段--创建时间
      //搜索查询条件
      searchFrom: {
        page: 1,
        pageSize: 1000,
        sortBy: ["total"],
        mobile: "", //查询主叫
        amountId: "",
        dateEnd: "",
        dateBegin: "",
        uid: "",
        groupBy: ["user"],
        sortDesc: [true],
      },

      searchFromRight: {},
      userChannel_id: [], //用户渠道
      amount_id: [], // 拿到的通信账户类型
      interface_id: [], // 拿到的接口类型
      user_list: [], // 用户的集合
      user_list1: [], //客服的集合
      tableHeight: 540,
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], // '12:00:00', '08:00:00'
      rules: {
        pickerDate: [
          {
            type: "date",
            required: false,
          },
        ],
        callTimeBegin: [
          { required: false, trigger: "blur", validator: validateMinutes },
        ],
        callTimeEnd: [
          { required: false, trigger: "blur", validator: validateMinutes },
        ],
      },
    };
  },
  watch: {
    // type(news,old){
    //     if( news == 'user' ){
    //         this.tableHeight = 540
    //     }else{getuserQuery
    //         this.tableHeight = 640
    //     }
    // }
  },
  mounted() {
    this.exportForm.dateBegin = this.pickerDate[0];
    this.exportForm.dateEnd = this.pickerDate[1];
    this.setTimeData();
    //this.getDownLowData();
    //  this.getuserQuery()
  },
  methods: {
    rowClassName(row) {
      if (this.searchFrom.groupBy[0] == "user") {
        if (this.selectedRow === row.row.uid) {
          return "default-row";
        } else {
          return "";
        }
      } else {
        if (this.selectedRow === row.row.amountId) {
          return "default-row";
        } else {
          return "";
        }
      }
    },
    rowClick(row) {
      let form = {};
      if (this.searchFrom.groupBy[0] == "user") {
        this.selectedRow = row.uid;
        form.uid = row.uid;
      } else {
        form.amountId = row.amountId;
        this.selectedRow = row.amountId;
      }

      amountts
        .riskCount({
          ...this.searchFrom,
          ...form,
          groupBy: ["amount", "user"],
        })
        .then((res) => {
          if (res.code !== 200) {
            this.$message.error(res.message);
            return false;
          } else {
            // this.countList = res.data.records
            this.downTable = res.data.records;
          }
        });
    },
    // 获取下拉数据
    getuserQuery() {
      userApp.userQuery({}).then((res) => {
        this.user_list1 = res.data;
        // console.log(res,'ooooooooooooooooooooo');
      });
    },
    getDownLowData() {
      // 获取通信账户类型
      amountts.riskCount({ ...this.searchFrom }).then((res) => {
        this.amount_id = res.data.records;
      });
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
      // 获取接口类型数组
      interfacets.getInterface({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    checkUserDetail(row) {
      if (this.type == "amount") {
        sessionStorage.setItem("amountId", row.amountId);
        // sessionStorage.setItem('amountId',row.amountId  )
      } else {
        sessionStorage.setItem("amountId", row.uid);
      }

      this.$router.push({
        path: "/open/userDetail",
        query: {
          type: this.type,
          id: this.type == "amount" ? row.amountId : row.userId,
          dateBegin: this.searchFrom.dateBegin,
          dateEnd: this.searchFrom.dateEnd,
          data: this.type == "amount" ? row.amountName : row.company,
        },
      });
    },
    // 查看详情跳转传参

    //主体列表数据
    getDownList(loading) {
      this.loading = loading;
      userNumberCount
        .accountList({
          ...this.searchFrom,
        })
        .then((res) => {
          // console.log(res,'pppppppppppppp');
          if (this.type == "amount") {
            res.data.count.amountCount == 0 ||
            res.data.count.amountCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "user") {
            res.data.count.userCount == 0 ||
            res.data.count.userCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "number") {
            res.data.count.numberCount == 0 ||
            res.data.count.numberCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "interfaces") {
            res.data.count.interfaceCount == 0 ||
            res.data.count.interfaceCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          console.log(res, "--------------");
          this.downTable = res.data.page.records;
          this.total = res.data.page.total;
          this.loading = false;
        });
    },
    //主叫单表数据
    getTelAList() {
      amountts
        .riskCount({
          ...this.searchFrom,
        })
        .then((res) => {
          if (res.code !== 200) {
            this.$message.error(res.message);
            return false;
          } else {
            this.countList = res.data.records;
            if (this.countList.length > 0) {
              if (this.searchFrom.groupBy[0] == "user") {
                this.selectedRow = this.countList[0].uid;
              } else {
                this.selectedRow = this.countList[0].amountId;
              }

              this.rowClick(this.countList[0]);
            } else {
              this.downTable = [];
            }
          }
        });
    },
    // 重置聚合导出
    exportReset() {
      this.exportForm = {
        amountId: "",
        interfaceId: "",
        dateBegin: "",
        dateEnd: "",
        callTimeBegin: "",
        callTimeEnd: "",
      };
      this.$refs.exportForm.resetFields();
      this.pickerDate = [];
    },
    // 确认聚合导出
    hanldeExport() {
      this.$refs.exportForm.validate((valid) => {
        if (!valid) {
          return;
        } else {
          userNumberCount.groupExport({ ...this.exportForm }).then((res) => {
            if (res.code != 200) {
              return message.error(res.message);
            } else {
              this.exportDialogVisible = false;
              this.$refs.exportForm.resetFields();
              this.pickerDate = [getDate(0), getDate(0)];
              message.success(res.data);
            }
          });
        }
      });
    },
    //选择日期
    selectDate(val) {
      if (val) {
        this.exportForm.dateBegin = val[0];
        this.exportForm.dateEnd = val[1];
      } else {
        this.exportForm.dateBegin = "";
        this.exportForm.dateEnd = "";
      }
    },
    // 刷新
    refreshTable() {
      this.searchFrom.page = 1;
      this.searchFrom.sortBy = ["date"];
      this.searchFrom.sortDesc = [true];
      this.time = "1";
      this.setTimeData();
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val;

      this.getTelAList();
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val;

      this.getTelAList();
    },
    // 日期选择器获取值
    setDataList() {
      this.timeList == null ? (this.timeList = []) : "";
      this.time = null;
      this.searchFrom.dateBegin = this.timeList[0];
      this.searchFrom.dateEnd = this.timeList[1];

      this.getTelAList();
    },
    // 设置日期时间
    setTimeData() {
      let listDate = this.setDate(this.time);
      this.timeList = this.setDate(this.time);
      this.searchFrom.dateBegin = listDate[0];
      this.searchFrom.dateEnd = listDate[1];

      this.getTelAList();
    },
    // 获取维度
    setTypeData() {
      this.getTelAList();

      // this.searchFrom.groupBy = [this.type]
    },
    // 文件导出
    setSearchExport() {
      if (this.type == "telA") {
        userNumberCount
          .accountCallingExport({
            ...this.searchFrom,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
            message.success(res.data);
          });
      } else {
        userNumberCount
          .exportList({
            ...this.searchFrom,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
            message.success(res.data);
          });
      }
    },
    // 获取日期时间段
    setDate(type) {
      switch (type) {
        case "1":
          return [getDate(0), getDate(0)];
        case "2":
          return [getDate(-1), getDate(-1)];
        case "3":
          return [getMonday("s", 0), getMonday("e", 0)];
        case "4":
          return [getMonday("s", -1), getMonday("e", -1)];
        case "5":
          return [getMonth("s", 0), getMonth("e", 0)];
        case "6":
          return [getMonth("s", -1), getMonth("e", -1)];
        default:
          return [];
      }
    },
    // 表格排序
    changeTableSort(column) {
      if (column.prop == null) {
        ElMessage({
          showClose: true,
          message: "当前已是向下排序",
          type: "warning",
        });
      } else {
        this.searchFrom.sortBy = [column.prop];
        column.order == "ascending"
          ? (this.searchFrom.sortDesc = [false])
          : (this.searchFrom.sortDesc = [true]);
        if (this.type == "telA") {
          this.getTelAList();
        } else {
          this.getDownList(true);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table .el-table__row:hover {
 // background-color: #8b9efc !important; /* 设置默认行背景颜色 */

}
::v-deep.el-table .default-row {
  background-color: #adbbff !important; /* 设置默认行背景颜色 */
}

::v-deep.el-table .default-row1:hover {
//background-color: #fff !important; /* 设置默认行背景颜色 */
}
::v-deep.el-table .default-row1:hover {
//background-color: #fff !important; /* 设置默认行背景颜色 */
}
::v-deep .el-table__row .selected {
  background-color: #f5f7fa !important; /* 设置默认行背景颜色 */

}

::v-deep .el-table__row .selected:hover  {
  background-color: #8b9efc !important; /* 设置默认行背景颜色 */

}
.newbill {
  padding-right: 1.125rem;
}
.first-name {
  font-size: 14px;
  font-weight: 400;
  color: #131523;
}
.pagination-box {
  height: 60px;
}
.title-span {
  display: inline-block;
  width: 80px;
}
</style>

// 注册用户管理
import { Request } from '@/http/request'

// 注册用户列表
export function userList (parameter: any) {
  return Request.axiosInstance.post('/web/user/list', parameter)
}
// 注册信息完善
export function perfection (parameter: any) {
    return Request.axiosInstance.post('/web/user/information-perfection', parameter)
}
// 启用用户 
export function enableUser (parameter: any) {
    return Request.axiosInstance.post('/web/user/enable', parameter)
}
// 禁用用户 
export function disableUser (parameter: any) {
    return Request.axiosInstance.post('/web/user/disable', parameter)
}
// 用户余额充值 
export function addBalance (parameter: any) {
    return Request.axiosInstance.post('/web/user/add-balance', parameter)
}
// 扣费 
export function decBalance (parameter: any) {
    return Request.axiosInstance.post('/web/user/dec-balance', parameter)
}
// 用户列表导出
export function exportList (parameter: any) {
    return Request.axiosInstance.post('/web/user/export', parameter)
}
// 新增用户 
export function addUser (parameter: any) {
    return Request.axiosInstance.post('/web/user/add', parameter)
}
// 新增用户 
export function deleteUser (parameter: any) {
    return Request.axiosInstance.post('/web/user/delete', parameter)
}
// 所属客服查询
export function userQuery(parameter: any) {
  return Request.axiosInstance.post('/system/admin-user/query', parameter)
}
export function saveOrUpdate(parameter: any) {
  return Request.axiosInstance.post('/web/webUserAffiliation/saveOrUpdate', parameter)
}
export function batchSetInviteUserName(parameter: any) {
    return Request.axiosInstance.post('/web/user/batchSetInviteUserName', parameter)
  }
export default {saveOrUpdate,userQuery, userList, enableUser, disableUser, addBalance, decBalance, exportList, addUser, perfection, deleteUser,batchSetInviteUserName }